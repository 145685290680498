import React from "react";

export const Faq = (props) => {
  return (
    <div id="faq" className="text-center" style={{ paddingTop: "100px" }}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="section-title">
              <h2>FAQ</h2>
              <p>{props.data ? props.data.paragraph : ""}</p>
              <ul>
                {props.data && props.data.faqList
                  ? props.data.faqList.map((faq, index) => (
                      <li key={`${faq}-${index}`}>{faq}</li>
                    ))
                  : null}
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img src="img/cut.png" className="img-responsive" alt="FAQ Image" />
          </div>
        </div>
      </div>
    </div>
  );
};
