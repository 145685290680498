import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => {
                const bulletPoints = d.text
                  .split(".")
                  .filter((sentence) => sentence.trim() !== "") // Remove any empty sentences
                  .slice(0, 3); // Limit to first 3 sentences

                return (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    <i className={d.icon}></i>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <ul
                        style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      >
                        {bulletPoints.map((sentence, index) => (
                          <li key={index}>{sentence.trim()}.</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};
