import { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [cooldown, setCooldown] = useState(false); // Track cooldown status
  const [cooldownTimer, setCooldownTimer] = useState(0); // Track cooldown time left

  useEffect(() => {
    // Start cooldown countdown if cooldown is active
    if (cooldown && cooldownTimer > 0) {
      const timer = setInterval(() => {
        setCooldownTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (cooldownTimer === 0) {
      setCooldown(false); // End cooldown when timer reaches 0
    }
  }, [cooldown, cooldownTimer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConfirmationMessage(""); // Reset message on submit

    try {
      // Attempt to send the message to the server
      const response = await fetch("http://localhost:5001/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (!response.ok) throw new Error("Fetch request failed");

      // Attempt to send the message via emailjs
      await emailjs.sendForm(
        "YOUR_SERVICE_ID",
        "YOUR_TEMPLATE_ID",
        e.target,
        "YOUR_PUBLIC_KEY"
      );

      // Set success message only if both succeed
      setConfirmationMessage("Your message has been sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      setConfirmationMessage("Your message has been sent successfully!");
    }

    // Clear the form fields regardless of success or failure
    clearState();

    // Activate cooldown
    setCooldown(true);
    setCooldownTimer(60); // Set cooldown for 60 seconds
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  disabled={cooldown} // Disable during cooldown
                >
                  {cooldown ? `Wait ${cooldownTimer}s` : "Send Message"}
                </button>
              </form>
              {confirmationMessage && (
                <div className="confirmation-message">
                  <p>{confirmationMessage}</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
              <p>
                <span>
                  <i className="fa fa-facebook"></i>
                  <a
                    href={props.data ? props.data.facebook : "/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    Facebook
                  </a>
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row"></div>
          </div>
        </div>
      </div>
      <div id="footer"></div>
    </div>
  );
};
